import * as React from 'react'
import NumberFormat from 'react-number-format';
import { BtcInfoContext } from './SatoshiPriceProvider';

export interface Props {
  satoshi: number,
  decimals?:number
}

const SatoshiToUsd: React.FC<Props> = ({ satoshi, decimals=0 }: Props) => {
  const satoshiPriceContext = React.useContext(BtcInfoContext);
  const [usd, setUsd] = React.useState<number>(null);

  React.useEffect(() => {
      const us = satoshiPriceContext.satoshiUsdPrice * satoshi;
      const rounded = Math.round(us * 10**decimals)/10**decimals;
      setUsd(rounded);
  }, [satoshi, decimals, satoshiPriceContext]);

  const satoshiDefined = !(satoshi === undefined || satoshi === null);
  return (
    <React.Fragment>
      { !satoshiDefined && 
        '-'
      }

      {satoshiDefined &&
        <NumberFormat displayType={'text'} thousandSeparator={true} value={usd} />
      }
    </React.Fragment>
  );
}

export default SatoshiToUsd;


