
import { gql, useQuery } from '@apollo/client';
import { GraphQlPaginated, GraphQlQueryResponse } from '../../general/GraphQlQueryResponse';
import { ISimpleNode } from './ISimpleNode';



export interface IUserNodeLike {
  id: string,
  count: number,
  userLiked: boolean
}


const QUERY = gql`
query nodeSearch($search: String!) {
  NodeSearch(search: $search) {
  count
  elements {
    id
    label
    channelCount
    capacitySat
  }
}
}
`;

export function useNodeSearch(search: string): GraphQlQueryResponse<GraphQlPaginated<ISimpleNode>> {
  const query = useQuery(QUERY, {
    variables: {
      search: search
    }
  });

  const ret = {
    loading: query.loading,
    error: query.error,
    data: query.data?.NodeSearch,
    refetch: query.refetch
  }
  return ret;
}
