import * as React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';
import AmbossNodeLink from './AmbossNodeLink';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from 'next/link';

type Props = {
  nodeId: string,
  allowCopy?: boolean,
  maxCharacters?: number,
  ambossLink?: boolean,
  lnrouterLink?: boolean
}

const NodePubkey = ({ nodeId, allowCopy = true, ambossLink = false, lnrouterLink = false, maxCharacters = 12 }: Props) => {
  const [icon, setIcon] = React.useState<"copy" | "check">("copy");

  function onCopied() {
    setIcon("check");
    setTimeout(() => {
      setIcon("copy");
    }, 3000);
  }

  if (!nodeId) {
    return <React.Fragment />
  }

  const maxCharacters2 = Math.round(maxCharacters / 2);
  return (
    <React.Fragment>
      <span className="mr-1">
        {nodeId.slice(0, maxCharacters2)}...{nodeId.slice(nodeId.length - maxCharacters2)}
      </span>

      {allowCopy &&
        <React.Fragment>
          {icon === "copy" &&
            <span title="Copy pubkey">
              <CopyToClipboard text={nodeId} onCopy={onCopied}>
                <ContentCopyIcon
                  fontSize="inherit" color="inherit" className="mb-1 cursor-pointer hover:text-gray-700"
                />
              </CopyToClipboard>
            </span>
          }
          {icon === 'check' &&
            <CheckIcon
              fontSize="inherit" color="inherit" className="mb-1 text-viking-700"
            />
          }
          {ambossLink &&
            <AmbossNodeLink nodeId={nodeId} />
          }
          {lnrouterLink &&
            <Link href={`/node/${nodeId}`}>
              <a title="Open on lnrouter.app" className={` hover:text-gray-700`} target="_blank">
                <OpenInNewIcon fontSize="inherit" />
              </a>
            </Link>

          }

        </React.Fragment>
      }

    </React.Fragment>
  );
};

export default NodePubkey;