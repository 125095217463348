import * as React from 'react'


function insertAtPosition(base: string, insert: string, positionFromRight: number): string {
  const position = base.length - positionFromRight;
  return base.slice(0, position)
    + insert
    + base.slice(position);
}

function getDecimalSeperator(locale: string): string {
  const formatted = Number(1.1).toLocaleString(locale);
  return formatted[1];
}

interface Props {
  satoshi: number,
  locale?: string,
  decimals?: number
}

const FormatSatoshi: React.FC<Props> = ({ satoshi, locale, decimals = 8 }: Props) => {
  const thousandSeperator = ' ';
  const decimalSeperator = getDecimalSeperator(locale);

  const decimalsToRemove = 8 - decimals;

  const roundedSat = Math.round(satoshi / 10**decimalsToRemove) * (10**decimalsToRemove);

  let formatted = roundedSat.toString().padStart(9, '0');

  formatted = insertAtPosition(formatted, thousandSeperator, 3);
  formatted = insertAtPosition(formatted, thousandSeperator, 7);
  formatted = insertAtPosition(formatted, decimalSeperator, 10); // 23.63 523 663


  let removeChars = 0;
  if (decimalsToRemove <= 2) {
    removeChars = decimalsToRemove;
  } else if (decimalsToRemove <= 5) {
    removeChars = decimalsToRemove + 1; // Remove 1 space.
  } else if (decimalsToRemove <= 7) {
    removeChars = decimalsToRemove + 2; // Remove 2 space.
  } else if (decimalsToRemove == 8) {
    removeChars = decimalsToRemove + 3; // Remove 2 space + point.
  }

  formatted = formatted.slice(0, formatted.length - removeChars);

  return (
    <React.Fragment>
      {formatted}
    </React.Fragment>
  );
}

export default FormatSatoshi;


