import * as React from 'react'
import ActionButton from '../../../../general/ActionButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import { useLnRouterTrial } from '../../../../auth/plans/useLnRouterTrial';
import { sprayConfetti } from '../../../../general/prayConfetti';
import WhiteContainer from '../../../../layout/WhiteContainer';
import { useUserPlan } from '../../../../auth/plans/useUserPlan';
import { useLnRouterUser } from '../../../../auth/useLnRouterUser';

type Props = {
  onClose?: () => any
}



const UserTrialAdBox: React.FC<Props> = ({ onClose }) => {
  const trial = useLnRouterTrial();
  const user = useLnRouterUser();
  const plan = useUserPlan();
  const [activating, setActivating] = React.useState(false);
  const [success, setSuccess] = React.useState(false);



  React.useEffect(() => {
    if (success) {
      sprayConfetti(document.documentElement);
    }
  }, [success]);

  if (!success && !trial.available) {
    return <React.Fragment />
  }

  return (
    <div className='text-left' >
      {!success && trial.available &&

        <WhiteContainer className="px-8 py-4 shadow-br">

          <h3 className="text-xl mb-2 font-black">Free 24h Trial</h3>
          <p className="">Get full access to LnRouter at zero costs for the next 24 hours.</p>

          <table className=" mt-6 text-sm font-light">
            <tbody>
              <tr>
                <td>
                  <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                </td>
                <td>
                  Routing Score
                </td>
              </tr>
              <tr>
                <td>
                  <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                </td>
                <td>
                  Channel Recommender
                </td>
              </tr>
              <tr>
                <td>
                  <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                </td>
                <td>
                  HTLC Response Times
                </td>
              </tr>
              <tr>
                <td>
                  <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                </td>
                <td>
                  Detailed Test Data
                </td>
              </tr>
              <tr>
                <td>
                  <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                </td>
                <td>
                  Unlimited Access
                </td>
              </tr>
            </tbody>
          </table>

          <div className='pt-8 flex gap-2'>

            <ActionButton isLoading={activating} design="action" onClick={async () => {
              setActivating(true);
              await trial.activateTrial();
              setActivating(false);
              setSuccess(true);
            }} className="">
              Activate now
            </ActionButton>

            {onClose &&
              <div className="">
                <ActionButton design="secondary" onClick={() => onClose()}
                >
                  Close
                </ActionButton>
              </div>
            }
          </div>
        </WhiteContainer>
      }
      {success &&
        <WhiteContainer className="px-8 py-4 shadow-br">
          <h3 className="text-xl mb-2 font-black">Trial activated!</h3>
          <p className="">You just got unlimited access to LnRouter for 24 hours.</p>

          <div className='p-8 flex justify-center items-center'>
            <CheckCircleOutlineIcon style={{fontSize: '128px'}} className="text-green-600" fontSize="inherit" color="inherit" />
          </div>


          {onClose &&
              <div className="text-right">
                <ActionButton design="default" onClick={() => onClose()}
                >
                  Let's go
                </ActionButton>
              </div>
            }
        </WhiteContainer>
      }
    </div>
  );
}

export default UserTrialAdBox;

