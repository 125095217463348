import canvasConfetti from 'canvas-confetti';

export function sprayConfetti(element: any) {
    const { top, height, left, width, } = element.getBoundingClientRect();
    const x = (left + width / 2) / window.innerWidth;
    const y = (top + height / 2) / window.innerHeight;
    const origin = { x, y };
    canvasConfetti({
      origin: origin
    });
  }