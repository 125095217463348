import * as React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format';


const FormatedNumber: React.FC<NumberFormatProps> = (props: NumberFormatProps) => {

  return (
    <NumberFormat 
    displayType={'text'} 
    thousandSeparator={true} 
    {...props}
     />
  );
}

export default FormatedNumber;


