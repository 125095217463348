import * as React from 'react'
import Loader from 'react-loader-spinner';

type Props = {
  height?: number,
  width?: number,
  centered?: boolean
  color?: string
}

const LoaderSpinner = ({centered=true, color="#27A3A4", ...rest }: Props) => {
  let classes = '';
  if (centered) {
    classes += ' flex justify-center'
  }
  return (
    <div className={classes}>
      <Loader {...rest} color={color} type="ThreeDots"></Loader>
    </div>
  );
};

export default LoaderSpinner;