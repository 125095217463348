import * as React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
    nodeId: string,
    children?: any,
    className?: string,
}

const AmbossNodeLink: React.FC<Props> = ({ nodeId, children, className = '' }: Props) => {
    if (!children) {
        // Return default if no children
       return  <a title="Open on amboss.space" className={className + ` hover:text-gray-700`} href={'https://amboss.space/node/' + nodeId} target="_blank">
            <OpenInNewIcon fontSize="inherit" />
        </a>
    }

    return (
        <a className={className} href={'https://amboss.space/node/' + nodeId} target="_blank">
            {children}
        </a>
    );
}

export default AmbossNodeLink;