import { gql, useMutation } from "@apollo/client";
import client from "../../../lib/graphql";
import { useNodeInfo } from "../../node/useNodeInfo";
import { useLnRouterUser } from "../useLnRouterUser";
import { useUserPlan } from "./useUserPlan";


export function useLnRouterTrial() {
    const user = useLnRouterUser();
    const plan = useUserPlan();
    const nodeInfo = useNodeInfo(user.data?.nodeId, {skip: !user.data?.nodeId});
    const [activateTrialMutation, { data, error }] = useMutation(gql`
    mutation activeTrial {
        activateTrial
    }
    `, {
      fetchPolicy: 'no-cache',
    });
    
    const trialAvailable = user.data?.trailAvailable;
    const nodeEligible = nodeInfo.data?.capacitySat >= 5*1000*1000;
    const isAuthenticated = plan.plan === 'AUTHENTICATED';

    return {
        activateTrial: async () => {
            await activateTrialMutation();
            await client.resetStore();
            await user.refetch();
        },
        available: trialAvailable && isAuthenticated && nodeEligible,
        loading: user.loading || plan.loading || nodeInfo.loading
    }
    
    
}