import * as React from 'react'


type Props = {
  children: any,
  className?: string,
  style?: React.CSSProperties,
}

const WhiteContainer: React.FC<Props> = ({ children, className='', style={} }) => {
  return (
    <div className={className + " bg-white dark:bg-viking-1100 rounded ring-1 ring-gray-100 dark:ring-gray-800"} style={style}>
      {children}
    </div>
  );
}

export default WhiteContainer;

