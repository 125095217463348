import * as React from 'react'
import Link from 'next/link';
import ActionButton from './ActionButton';
import { useLnRouterTrial } from '../auth/plans/useLnRouterTrial';
import { useUserPlan } from '../auth/plans/useUserPlan';
import { Backdrop } from '@mui/material';
import UserTrialAdBox from '../node/LookupReport/components/advertisement/UserTrialAdBox';


interface Props {
    size: 'small' | 'big',
    className?: string
}


const LandingPageSubscribeBtn: React.FC<Props> = ({ size, className }: Props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const trial = useLnRouterTrial();
    const plan = useUserPlan();

    const spanClass = size === 'big' ? 'text-2xl py-1' : 'text-lg';

    return <React.Fragment>

        {plan.plan === 'ANONYMOUS' &&
            <Link href="/auth/login">
                <a>
                    <ActionButton className={"bg-rose-600  hover:bg-rose-700 " + className}>
                        <span className={'px-4 text-light capitalize text-white  ' + spanClass}>Start free trial*</span>
                    </ActionButton>
                </a>
            </Link>
        }
        {plan.plan === 'AUTHENTICATED' && !trial.available &&
            <Link href="/account/profile">
                <a>
                    <ActionButton className={"bg-rose-600  hover:bg-rose-700 " + className}>
                        <span className={'px-4 text-light capitalize ' + spanClass}>Purchase plan</span>
                    </ActionButton>
                </a>
            </Link>
        }

        {plan.plan === 'AUTHENTICATED' && trial.available &&
            <div>
                <ActionButton className={"bg-rose-600  hover:bg-rose-700 " + className}
                    onClick={() => setOpen(true)}
                >
                    <span className={'px-4 text-light capitalize ' + spanClass}>Start free trial</span>
                </ActionButton>
            </div>
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={() => setOpen(false)}
        >
            <div className='text-black px-2' onClick={e => e.stopPropagation()}>
                <UserTrialAdBox onClose={() => setOpen(false)} />
            </div>
        </Backdrop>

    </React.Fragment>

}

export default LandingPageSubscribeBtn;


