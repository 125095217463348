import React from "react";

type Props = {
  children: any,
  className?: string,
  maxWidth?: string,
}

const TextContainer = ({ children, className='', maxWidth="800px" }: Props) => {
  return (
    <div className={"container px-4 md:px-32 lg:px-48 mx-auto " + className}>
      <div className="mx-auto" style={{maxWidth: maxWidth}}>
      {children}
      </div>
      
    </div>
  );
}

export default TextContainer;