import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { GraphNode } from '../../probe/models/GraphNode';
import { useRouter } from 'next/router';
import SearchIcon from '@mui/icons-material/Search';
import NodePubkey from '../../general/NodePubkey';
import { useNodeSearch } from './useNodeSearch';
import { ISimpleNode } from './ISimpleNode';
import FormatSatoshi from '../../general/FormatSatoshi';


function itemFormat(node: ISimpleNode, props: any) {
    if (!node) {
        return '';
    }
    return <div {...props}>
        <div>
            <div className='font-semibold text-lg'>{node.label}</div>
            <div className=" text-xs">
                {node.channelCount} channels, ₿<FormatSatoshi satoshi={node.capacitySat} decimals={2} />
            </div>
            <div className="text-xs text-gray-500">
                <NodePubkey allowCopy={false} nodeId={node.id} maxCharacters={24} />
            </div>

        </div>
    </div>
}

type Props = {
    onChange?: (node: ISimpleNode | GraphNode) => void,
    size?: "normal" | "big",
    autoFocus?: boolean,
    label?: string,
    className?: string,
    style?: any,
    expand?: boolean
}

const UrlTiedNodeInputField: React.FC<Props> = ({ onChange, label, size = "normal", autoFocus = false, className='', style={}, expand=true }: Props) => {
    const router = useRouter();
    const [isOpen, setOpen] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<ISimpleNode>(null);
    const [input, setInput] = React.useState<string>("");
    const [options, setOptions] = React.useState<ISimpleNode[]>([]);
    const searchResult = useNodeSearch(input);
    

    React.useEffect(() => {
        if (searchResult.loading) {
            return;
        }
        setOptions(searchResult.data?.elements || []);
    }, [searchResult.data]);

    function setResult(newVal: ISimpleNode | string) {
        if (!newVal) {
            return;
        }
        const node = newVal as ISimpleNode;
        router.push({
            pathname: `/node/${node.id}`,
        }, undefined, { shallow: true })

        setInput("");
        if (value === null) { // Small trick to clear the input
            setValue(undefined);
            setTimeout(() => {
                setValue(null)
            }, 50);
        } else {
            setValue(null);
        }

        if (onChange) {
            onChange(node)
        }
    }


    const isSSR = typeof window === 'undefined';
    const windowWidth = isSSR? 600: window.screen.width;
    const canExpand = windowWidth > 400;
    const componentWidth = ((isOpen && canExpand || size === 'big')) ? `w-80` : 'w-60';
    return (
        <Autocomplete
            className={`${expand? componentWidth: ''}` + '  ' + className}
            blurOnSelect={true}
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            popupIcon={<SearchIcon />}
            options={options}
            value={value}
            getOptionLabel={(node) => node.label + ' - ' + node.id}
            renderOption={(props, node) => {
                return itemFormat(node, props);
            }}
            noOptionsText='No nodes available'
            onChange={(event, value) => {
                console.log('set result', value);
                setResult(value);
            }}
            renderInput={(params) => {
                return <TextField
                    {...params}
                    label={label}
                    placeholder="Node alias or pubkey"
                    variant='outlined'
                    size={size === "normal" ? "small" : "medium"}
                    autoFocus={autoFocus}
                    style={style}
                    value={input}
                    onKeyPress={e => {
                        if (e.code === 'Enter') {
                            if (options.length > 0) {
                                const firstEle = options[0];
                                setOpen(false);
                                setResult(firstEle);
                            }
                        }
                    }}
                    onChange={e => {
                        setInput(e.target.value || "")
                    }}
                    className="bg-white no-border rounded focus:outline-none "
                />;
            }}

            isOptionEqualToValue={(option, value) => {
                return option.id === option.id;
            }}
        />
    );
}

export default UrlTiedNodeInputField;