
import React from "react";
import FormatedNumber from "../components/general/FormatedNumber";
import SatoshiToUsd from "../components/general/SatoshiToUsd";
import BaseLayout from "../components/layout/BaseLayout";
import TextContainer from "../components/layout/TextContainer";
import CheckIcon from '@mui/icons-material/Check';
import UrlTiedNodeInputField from "../components/node/nav/UrlTiedNodeInputField";
import LoaderSpinner from "../components/general/LoadingSpinner";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import SpeedIcon from '@mui/icons-material/Speed';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import LandingPageSubscribeBtn from "../components/general/LandingPageSubscribeBtn";
import Link from "next/link";
import Logo from "../components/layout/Logo";
import ActionButton from "../components/general/ActionButton";
import { ISimpleNode } from "../components/node/nav/ISimpleNode";


export default function LandingPage() {
  const [node, setNode] = React.useState<ISimpleNode>();

  return (
    <BaseLayout
      seoDescription="Opening channels is a skill in itself. Use LnRouter to vet nodes. Improve payment speed and capital efficiency"
      shareDescription="Opening channels is a skill in itself. Use LnRouter to vet nodes. Improve payment speed and capital efficiency"
      bottomMargin={false}
      showAdvertisement={false}
      disableOnMaintenance={false}
      disableEmailConfirmationAd={true}
    >
      <div className="bg-gradient-to-b from-gradientLight to-viking-500 pt-16 text-gray-900 px-2">
        <div className="pt-8 pb-16">
          <h1 className="font-extrabold text-4xl md:text-6xl text-center leading-[1.1] mb-4 mt-12">
            Premium Lightning Tools<br />
            To Create A Powerful Node
          </h1>
          <p className="text-center text-2xl pb-4">
            LnRouter helps node operators make <br />
            informed decisions with data and tools that matter.
          </p>

          <div className=" pt-8">
            {!node &&
              <div>
                <UrlTiedNodeInputField size="big" className="mx-auto w-72" onChange={value => setNode(value)}
                  expand={false}
                />
              </div>
            }

            {node &&
              <div>
                <div className="text-center font-bold text-2xl" style={{ marginBottom: '-20px' }}>
                  {node.label}
                </div>
                <LoaderSpinner width={50} />
              </div>
            }
          </div>
        </div>

      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#4adddf" fillOpacity="1" d="M0,128L16,144C32,160,64,192,96,208C128,224,160,224,192,213.3C224,203,256,181,288,181.3C320,181,352,203,384,181.3C416,160,448,96,480,90.7C512,85,544,139,576,154.7C608,171,640,149,672,170.7C704,192,736,256,768,250.7C800,245,832,171,864,144C896,117,928,139,960,170.7C992,203,1024,245,1056,256C1088,267,1120,245,1152,202.7C1184,160,1216,96,1248,101.3C1280,107,1312,181,1344,176C1376,171,1408,85,1424,42.7L1440,0L1440,0L1424,0C1408,0,1376,0,1344,0C1312,0,1280,0,1248,0C1216,0,1184,0,1152,0C1120,0,1088,0,1056,0C1024,0,992,0,960,0C928,0,896,0,864,0C832,0,800,0,768,0C736,0,704,0,672,0C640,0,608,0,576,0C544,0,512,0,480,0C448,0,416,0,384,0C352,0,320,0,288,0C256,0,224,0,192,0C160,0,128,0,96,0C64,0,32,0,16,0L0,0Z"></path>
      </svg>

      <div className="mt-12 mb-32 ">

        <div>
          <div className="flex justify-center">

          </div>
          <div className="lnrouter-container">
            <div className="pt-8 xl:px-48">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                <p className="bg-viking-400 font-extrabold italic px-4 py-2 text-2xl lg:text-3xl sm:col-span-3 text-center">
                  50% Of All Lightning Payments Take More Than 8.6 Seconds.
                </p>
                <div className="bg-viking-200 rounded p-6 text-xl font-light">
                  Payment delivery is not guaranteed.
                </div>
                <div className="bg-viking-200 rounded p-6 text-xl font-light">
                  Unreliable nodes make your payments stuck.
                </div>
                <div className="bg-viking-200 rounded p-6 text-xl font-light">
                  Building a well performing node is hard.
                </div>

              </div>
              <div className="flex items-center justify-center pt-24">
                <Logo />
                <p className=" font-extrabold italic px-4 py-2 text-2xl lg:text-3xl sm:col-span-3 text-center">
                  An attempt to improve routing.
                </p>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div className="px-2 py-36 bg-[#0E4243] text-white">
        <div className="lnrouter-container sm:grid lg:grid-cols-5 md:grid-cols-4 grid-cols-3">
          {/* Metric tiles */}
          <div className=" md:col-span-2 grid md:grid-cols-2 gap-4">
            {/* First element */}
            <div className="text-black bg-white text-center shadow-br">
              <div className="bg-gradient-to-br from-white to-viking-500/80 px-2 py-8">
                <div className="font-bold text-3xl">
                  1.6s
                </div>
                <div className="text-gray-500 text-sm">
                  Top 40th Percentile
                </div>
              </div>
              <div className="bg-white py-4 px-2 font-bold text-sm">
                HTLC RESPONSE TIME
              </div>
            </div>
            <div></div>
            <div className="text-black bg-white text-center  shadow-br">
              <div className="bg-gradient-to-br from-white to-viking-500/80 px-2 py-8">
                <div className="font-bold text-3xl">
                  80%
                </div>
                <div className="text-gray-500 text-sm">
                  Top 84th Percentile
                </div>
              </div>
              <div className="bg-white py-4 px-2 font-bold text-sm">
                BALANCED CHANNELS
              </div>
            </div>
            <div className="text-black bg-white text-center shadow-br">
              <div className="bg-gradient-to-br from-white to-viking-500/80 px-2 py-8">
                <div className="font-bold text-3xl">
                  2.23
                </div>
                <div className="text-gray-500 text-sm">
                  Top 34th Percentile
                </div>
              </div>
              <div className="bg-white py-1 px-2 font-bold text-sm">
                AVERAGE HOPS <br /> TO NETWORK
              </div>
            </div>
          </div>
          {/* Text */}
          <div className="col-span-2 lg:col-span-3  text-left sm:pl-32 pt-12 sm:pt-0">
            <h2 className="font-extrabold text-4xl leading-[1.1] mb-4 ">
              We Measure What Is Important
            </h2>
            <p className=" text-xl">
              LnRouter scans and collects statistics of Thousands of nodes every month, giving you instant access to the most critical metrics.
            </p>
            <div className="pt-6">
              <LandingPageSubscribeBtn size={"small"} className="mr-2" />

            </div>
          </div>

        </div>

      </div>




      <div className=" bg-viking-500 text-black py-36">
        <div className="lnrouter-container grid sm:grid-cols-2 grid-cols-1">
          <div className="pr-24">
            <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 ">
              Personalized Channel Recommender
            </h2>
            <div className="text-xl ">
              The recommender engine proposes high performing routing nodes that optimize your payment speed and graph position.
              Build a powerful node with recommendations tailor-made for you.
            </div>
            <div className="mt-6 flex flex-wrap">
              <LandingPageSubscribeBtn size={"small"} className="mr-2 mb-1" />
              <Link href="/channel-recommender-preview">
                <a>
                  <ActionButton design="secondary" className="text-lg">
                    <div className="capitalize">
                      More Info
                    </div>
                  </ActionButton>
                </a>
              </Link>
            </div>
          </div>
          <div className="flex pt-12 sm:pt-0">
            <img
              src="/imgs/graph-example2.jpg"
              className=" rounded-xl border-2 border-white float-right object-cover" />
          </div>
        </div>


        <div className="lnrouter-container grid grid-cols-2 sm:grid-cols-3 gap-4 md:grid-cols-4 pt-24">
          <div className="flex items-center">
            <ArrowCircleUpIcon className="text-4xl mr-1" fontSize="inherit" color="inherit" />
            <div className="border-l pl-1 border-viking-700">
              Payment success
            </div>
          </div>
          <div className="flex items-center">
            <ArrowCircleDownIcon className="text-4xl mr-1" fontSize="inherit" color="inherit" />
            <div className="border-l pl-1 border-viking-700">
              Stuck payments
            </div>
          </div>
          <div className="flex items-center">
            <SpeedIcon className="text-4xl mr-1" fontSize="inherit" color="inherit" />
            <div className="border-l pl-1 border-viking-700">
              Faster payments
            </div>
          </div>
        </div>
      </div>


      <div className="px-2 py-36 bg-[#E5FFE5]">
        <div className="lnrouter-container sm:grid lg:grid-cols-5 md:grid-cols-4 grid-cols-3">
          {/* Metric tiles */}
          <div className=" md:col-span-2 grid md:grid-cols-2 gap-4">
            {/* First element */}
            <div>
              <div className="text-black bg-white text-center shadow-br">
                <div className="bg-gradient-to-br from-white to-viking-500/80 px-2 py-12">
                  <div className="text-gray-500 text-sm">
                    Top Percentile
                  </div>
                  <div className="font-bold text-5xl">
                    93th
                  </div>
                  <div className="text-gray-500 text-sm">
                    *The higher the better
                  </div>
                </div>
                <div className="bg-white py-4 px-2 font-bold text-sm">
                  ROUTING SCORE
                </div>
              </div>
            </div>
          </div>
          {/* Text */}
          <div className="col-span-2 lg:col-span-3  text-left sm:pl-32 pt-12 sm:pt-0 ">
            <h2 className="font-extrabold text-4xl leading-[1.1] mb-4 ">
              A Unique Score
            </h2>
            <p className=" text-xl">
              A dependable routing partner manages its liquidity, responds quickly,
              and has channels to essential nodes in the network. Our routing score models
              payment speed and delivery directly.
              Check out how your node performs with a single score that summerizes
              the most important metrics.
            </p>
            <div className="pt-6 flex flex-wrap">
              <LandingPageSubscribeBtn size={"small"} className="mr-2 mb-1" />
              <a href="https://blog.lnrouter.app/lightning-payment-speed-2022" target="_blank">
                <ActionButton design="secondary" className="text-lg ">
                  <div className="capitalize">
                    More Info
                  </div>
                </ActionButton>
              </a>
            </div>
          </div>

        </div>

      </div>



      <div className="py-32 ">
        <div className="lnrouter-container">
          <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 ">
            What Users Say
          </h2>
        </div>

        <div className="lnrouter-container flex flex-wrap gap-8">
          <div className="shadow-br  rounded-xl py-8 px-8 bg-white max-w-[500px] flex flex-col" >
            <div>
              <div className=" rounded-full bg-viking-200 px-2 inline-block text-xs text-viking-800 uppercase tracking-wider">
                Service node
              </div>
            </div>

            <div className="text-sm italic pt-4" style={{ fontWeight: 300 }}>

              <p className="text-xl font-semibold pb-2">
                "My worries about failed payments are gone."
              </p>
              <p className="text-gray-500 max-w-md" >
                "At LNPay it is a challenge to maintain a high level of payment deliverability.
                After establishing channels with good routing nodes
                on LnRouter, delivery success increased dramatically and payments got quicker.
                Having LnRouter as a tool is a huge relief."
              </p>
            </div>
            <div className="grow flex flex-col-reverse">
              <div className="flex items-center pt-8">
                <div>
                  <img
                    src="/imgs/lookups/timK.jpeg"
                    className=" w-8 filter drop-shadow-2xl rounded-full border" />
                </div>
                <div>
                  <a className="font-bold pl-2 pr-2 hover:text-viking-700 text-lg"
                    target="_blank"
                    href="https://lnpay.co">
                    Tim from LNPay.co
                  </a>
                </div>
                <div>
                  <span className="text-gray-400 text-sm " style={{ fontWeight: 300 }}>Founder</span>
                </div>
              </div>
            </div>
          </div>

          <div className="shadow-br rounded-xl py-8 px-8 bg-white max-w-[500px]" >
            <div className=" rounded-full bg-viking-200 px-2 inline-block text-xs text-viking-800 uppercase tracking-wider">
              Routing node
            </div>
            <div className="text-sm italic pt-4" style={{ fontWeight: 300 }}>
              <p className="text-xl font-semibold pb-2">
                "LnRouter has helped me transform from a recreational node to a profitable router."
              </p>
              <p className="text-gray-500" >
                "The insights I gained from LnRouter helped me keep my inbound sources of
                liquidity balanced and flowing. <br />
                This app is like accessing the dashboard of other nodes for a limited snapshot of their channels.
                It's a must-have when choosing a new peer or attempting to improve rebalancing with an existing peer."
              </p>
            </div>

            <div className="flex items-center pt-8">
              <div>
                <img
                  src="/imgs/lookups/azhodl.jpg"
                  className=" w-8 filter drop-shadow-2xl rounded-full border" />
              </div>
              <div>
                <a className="font-bold pl-2 pr-2 hover:text-viking-700"
                  target="_blank"
                  href="https://azhodl.com/connect/">
                  AZHODL
                </a>
              </div>
              <div>
                <span className="text-gray-400 text-sm " style={{ fontWeight: 300 }}>Successful node operator</span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="py-32 bg-viking-1000 text-white px-2 ">
        <TextContainer>
          <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 text-center ">
            Pricing
          </h2>
          <div className="flex justify-center">
            <div className="py-6 px-8 border drop-shadow-2xl rounded-xl bg-white text-black w-72">
              <p className="font-bold text-2xl mb-2 text-viking-700">PRO Plan</p>
              <p ><span className="text-2xl font-extrabold"><FormatedNumber value={25200} />sat</span>
                <span className="text-sm text-gray-400 font-normal"> = $<SatoshiToUsd satoshi={25200} decimals={1} /></span>
              </p>
              <p className="text-gray-400 text-xs">per month</p>

              <table className=" mt-6 text-sm font-light">
                <tbody>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      Routing Score
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      Channel Recommender
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      HTLC Response Times
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      Unlimited Access
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="pt-8 mx-auto w-72">
            <div>
              <LandingPageSubscribeBtn size="small" className="w-full" />
              <p className="text-sm pt-1">
                *For nodes with more than 5,000,000sat public capacity.
              </p>
            </div>
          </div>

        </TextContainer>

      </div>
      <div className=" bg-viking-500 text-black py-36">
        <div className="lnrouter-container">
          <div className="">
            <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-8 ">
              So much more
            </h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8">

            <Link href="/liquidity-ads">
              <a>
                <div className=" hover:scale-105 cursor-pointer">
                  <div className="">
                    <img
                      src="/imgs/preview/lnrouter_liqads.jpg"
                      className="rounded border-2 border-viking-700 object-cover aspect-[1.5]" />
                  </div>
                  <div className="pl-1">
                    <div className=" font-semibold text-lg pt-2 ">
                      Liquidity Ad Marketplace
                    </div>
                  </div>

                </div>
              </a>
            </Link>
            <Link href="/graph">
              <a>
                <div className=" hover:scale-105 cursor-pointer">
                  <div className="">
                    <img
                      src="/imgs/preview/lnrouter_graph.jpg"
                      className="rounded border-2 border-viking-700 object-cover aspect-[1.5]" />
                  </div>
                  <div className=" font-semibold text-lg pt-2 pl-1">
                    Lightning Network Graph
                  </div>
                </div>
              </a>
            </Link>

            <Link href="/explore">
              <a>
                <div className=" hover:scale-105 cursor-pointer">
                  <div className="">
                    <img
                      src="/imgs/preview/lnrouter_nodeexplorer.jpg"
                      className="rounded border-2 border-viking-700 object-cover aspect-[1.5]" />
                  </div>
                  <div className=" font-semibold text-lg pt-2 pl-1">
                    Node Explorer
                  </div>
                </div>
              </a>
            </Link>

            <Link href="/channel-recommender">
              <a>
                <div className=" hover:scale-105 cursor-pointer">
                  <div className="">
                    <img
                      src="/imgs/preview/lnrouter_channelrecom.jpg"
                      className="rounded border-2 border-viking-700 object-cover aspect-[1.5]" />
                  </div>
                  <div className=" font-semibold text-lg pt-2 pl-1">
                    Channel Recommender
                  </div>
                </div>
              </a>
            </Link>

            <Link href="/graph/zero-base-fee">
              <a>
                <div className=" hover:scale-105 cursor-pointer">
                  <div className="">
                    <img
                      src="/imgs/preview/lnrouter_zerobasefee.jpg"
                      className="rounded border-2 border-viking-700 object-cover aspect-[1.5]" />
                  </div>
                  <div className=" font-semibold text-lg pt-2 pl-1">
                    #zeroBaseFee Graph
                  </div>
                </div>
              </a>
            </Link>

            <Link href="/get-route">
              <a>
                <div className=" hover:scale-105 cursor-pointer">
                  <div className="">
                    <img
                      src="/imgs/preview/lnrouter_getroute.jpg"
                      className="rounded border-2 border-viking-700 object-cover aspect-[1.5]" />
                  </div>
                  <div className=" font-semibold text-lg pt-2 pl-1">
                    Shortest Path
                  </div>
                </div>
              </a>
            </Link>

          </div>
        </div>

      </div>


      <div className="py-32 bg-gray-200 text-center text-xl px-2 ">

        <div className="pb-4 text-2xl font-bold">
          Explore The Lightning Network Now
        </div>

      </div>

    </BaseLayout>
  );
}
